import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

import Pricing from 'components/pricing';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';


import Icon from '@mui/material/Icon';

import { red, grey } from '@mui/material/colors';

import { useSelector, useDispatch } from 'react-redux';

import FacebookIcon from './facebook.png';
import GoogleIcon from './google.png';
import TwitterIcon from './twitter.png';
import GoogleButtonIcon from './googleButton.png';

//import { CSSTransitionGroup } from 'react-transition-group';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
import intro from './intro.png';
import extra1 from './extra1.png';
import extra2 from './extra2.png';
import extra3 from './extra3.png';

import ExtensionButton from 'components/extensionButton';


const Home = props => {

	const [content, setContent] = React.useState('loading');
	const [open, setOpen] = React.useState(true);
  const [isHowOpen, setIsHowOpen] = React.useState(false);
	const [loadedTime, setLoadedTime] = React.useState(null);
	const { root, CLIENT_ROOT } = useSelector(state => state.api);
	const sign = useSelector(state => state.sign);

	const [key, setKey] = React.useState('a');
	const [Loaded, setLoaded] = React.useState(false);

  const location = useLocation();
  const url = (location.pathname+location.search).substr(1)
	const user = useSelector(state => state.user);

  const extra = React.useRef(null)

  const dispatch = useDispatch();

	//const url_string = props.match.params.url;

  let history = useNavigate();


  const openExtensionPage= () => {
    var url = 'https://chrome.google.com/webstore/detail/trends/malnhaodjdegcljjebmpopbjmckblndn';
    window.open(url,'_blank');
  }

  const rootA = process.env.NODE_ENV === 'development'? 'http://localhost:8080':'https://api.platfor.io'; 

  const handleSignOut = () => {
		fetch(rootA + `/auth/signout`, { credentials: 'include' })
			.then(res => {
				return res.json();
			})
  		.then(json => {
  				console.log('json is', json)
  				dispatch({type: 'SET_USER', path: '/auth/user', method: 'get'});
  			})
  		.catch(err => console.log(err))
  }

  const validURL = string => {
      const  url = 'https://' + string;
      console.log('url is', url);
      try { return Boolean(new URL(url)); }
      catch(e){ console.log('not valid'); return false; 
    }
  }

  useEffect(() => {
    const image1 = new Image();
    const image2 = new Image();
    image1.src = './sample1.png';
    image2.src = './sample2.png';
  }, []);


  const handleClose = () => { 
    dispatch({ type: 'CLOSE_SIGN_$' })
  };

  const openGCP = () => {
    var url = 'https://cloud.google.com/security';
    window.open(url,'_blank');
  }

  const openTwitter = () => {
    var url = 'https://reddit.com/r/socialbrowse';
    window.open(url,'_blank');
  }

  const signIn = name => {
    const root = process.env.NODE_ENV === 'development'? 'http://localhost:8080':'https://api.socialbrowse.us'; 
    window.location.href = `${root}/auth/${name}?url=${encodeURIComponent(window.location.href)}`;
  };

  //'Share the screen you\'re seeing between friends'
  let h1 = '1. Right click or ctrl + x on the target'
  let ps = ['to choose the accurate element', '2. Write a #hashtag and press enter key']

  let signInWord = 'Alreaedy installed Plat? Please sign in'; 
  let how = 'Leave your comment on any webpage screen. Your action is notified to the other browser users following you or interested in the webpage keywords in real time. They can reply to your words viewing the same screen and suggest you the webpages you need in the same way.';
  let notice = '**Platfor records people\'s speeches on the public URL of a webpage. It doesn\'t work on Private webpage and cannot access any of your personal information'; 

  let ex1_h1 = '2. Use them on your bookmark page';
  let ex1_ps = [ 'The target is gathered on https://socialbrowse.us/:your_name/:hashtag_name', 'You can use all of them without searching each one again and again']

  let ex2_h1 = 'Get alarmed in real time'
  let ex2_ps = [ 'When your friend sticker for you, your browser notify you in real time', 'Go to the location he or she stickered']

  let ex3_h1 = 'Give us your feedback';
  let ex3_ps = [ 'Socialbrowse is being improved by your support', 'It is beta and help us to upgrade it']

  let kr_h1 = '보고있는 정보에 스티커를 붙이세요';
  let kr_ps = ['브라우저로 보고있는 위치에 스티커를 붙이세요', '친구들과 브라우저로 보고있는 위치를 서로 공유하세요'];
  let kr_signInWord = 'PLAT을 설치하셨나요? 로그인하세요'
  let kr_how = '모든 웹페이지 화면에 당신의 생각을 자유롭게 남기세요. 당신의 활동은 당신을 팔로우하거나 해당 웹페이지 키워드에 관심있는 브라우저 이용자들에게 실시간으로 전달됩니다. 그들은 같은 화면에서 당신의 말에 답변할 수 있고 같은 방식으로 당신이 필요한 웹페이지를 추천해줄 수 있습니다.'
  let kr_notice = 'Socialbrowse는 사람들의 대화를 웹페이지의 공개 URL에 저장합니다. 따라서 사적인 웹페이지에서는 작동하지 않으며 당신의 어떠한 개인정보에도 접근할 수 없습니다.';

  let kr_ex1_h1 = '스티커의 위치로 언제든지 돌아가세요';
  let kr_ex1_ps = [ 'Socialbrowse는 당신의 스티커 활동의 피드를 제공합니다', '스티커를 붙인 정보들을 한꺼번에 볼 수 있습니다. 언제든지 피드를 클릭해 원래 위치로 돌아갈 수 있습니다']
  let kr_ex2_h1 = '친구가 스티커를 붙인 위치로도 갈 수 있습니다';
  let kr_ex2_ps = [ '친구가 스티커를 붙이면 당신의 브라우저가 실시간으로 알림을 받습니다', '붙어있는 스티커에 좋아요를 누르거나 답장을 적을 수 있습니다']
  let kr_ex3_h1 = '항상 무료이고 안전합니다';
  let kr_ex3_ps = [ 'Socialbrowse는 당신의 스티커를 Facebook, Twitter처럼 웹페이지의 공개 URL에 저장합니다', '누구에게나 공개된 웹페이지 화면에서만 작동하며 당신의 어떠한 개인정보에도 접근할 수 없습니다']

  if(location.pathname=='/kr'){h1 = kr_h1; ps = kr_ps;
    how = kr_how;
    notice = kr_notice;

    ex1_h1 = kr_ex1_h1;
    ex1_ps = kr_ex1_ps;

    ex2_h1 = kr_ex2_h1;
    ex2_ps = kr_ex2_ps;

    ex3_h1 = kr_ex3_h1;
    ex3_ps = kr_ex3_ps;
  }

  const { lang } = useParams();

  //<h1 className={classes.h1}>Stay connected with friends while surfing internet</h1>
	return (
      <div align='center' style={{marginTop: '50px'}}>
        <div>
          <div style={{ margin: '50px', mmaxWidth: '550px' }}>
            <h1 style={{fontSize: '80px', color: 'black', fontWeight: 600}}>How to use</h1>
          </div>
        </div>

        <div style={{margin: '50px'}}>
          <div style={{ maxWidth: '550px', marginBottom: '50px', marginTop: '100px' }}>
            <h1>{h1}</h1>
            {
              /*
              <p style={{textAlign: 'left', fontSize: '15px', marginTop: '50px'}}>1.<mark> Drag and move the label </mark>{ps[0]}</p>
              <p style={{textAlign: 'left'}}>{ps[1]}</p>
              */
            }
         
          </div>
          <div>
            <img src={extra1} style={{width: '1000px'}}  alt="img of people talking on the shared screen"/>
          </div>
        </div>

        <div ref={extra} style={{margin: '50px'}}>
          <div style={{ maxWidth: '550px',  marginBottom: '50px' }}>
            <h1>{ex1_h1}</h1>
            {
              /*
              <p style={{textAlign: 'left', fontSize: '15px', marginTop: '50px'}}>{ex1_ps[0]}</p>
              <p style={{textAlign: 'left'}}>{ex1_ps[1]}</p>
              */

            }
           
          </div>
          <div>
            <img src={extra2} style={{width: '1000px'}}  alt="img of product sample"/>
          </div>
        </div>
        {/*


        <div style={{margin: '50px'}}>
            <div style={{ maxWidth: '550px', marginBottom: '50px' }}>
              <h1>{ex2_h1}</h1>
              <p style={{textAlign: 'left', fontSize: '15px', marginTop: '50px'}}>{ex2_ps[0]}</p>
              <p style={{textAlign: 'left'}}>{ex2_ps[1]}</p>
            </div>
          <div>
            <img src={intro} style={{width: '1000px'}}  alt="img of how this product works"/>
          </div>
        </div>

        <div style={{margin: '50px'}}>
          <div style={{ maxWidth: '550px', marginBottom: '50px' }}>
            <h1>{ex3_h1}</h1>
            <p style={{textAlign: 'left', marginTop: '50px'}}>{ex3_ps[0]}</p>
            <p style={{textAlign: 'left'}}>{ex3_ps[1]}</p>
          </div>
          <div>
            <img src={extra3} style={{width: '1000px'}}  alt="img of explaining security details"/>
          </div>
        </div>


          */
        }

      </div>
    );
}

export default Home;
