import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';

import './index.css';

import Icon from '@mui/material/Icon';

import { red, grey } from '@mui/material/colors';

import { useSelector, useDispatch } from 'react-redux';

import FacebookIcon from './facebook.png';
import GoogleIcon from './google.png';
import TwitterIcon from './twitter.png';
import GoogleButtonIcon from './googleButton.png';
import BookmarkIcon from '@mui/icons-material/Bookmark';

//import { CSSTransitionGroup } from 'react-transition-group';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'

import intro from './intro.png';
import extra1 from './extra1.png';
import extra2 from './extra2.png';
import extra3 from './extra3.png';
import hashIcon from './hashIcon.png';
import scissorIcon from './scissor.png'
import cartIcon from './cart.jpg';
import pinIcon from './pin.png';
import newspaperClippings from './newspaperClippings.jpg';
import scrapbook from './scrapbook.jpg';

import ExtensionButton from 'components/extensionButton';
import { Label } from 'react-konva';

//import { io } from 'socket.io-client';

const Home = props => {

	const [content, setContent] = React.useState('loading');
	const [open, setOpen] = React.useState(true);
  const [isHowOpen, setIsHowOpen] = React.useState(false);
	const [loadedTime, setLoadedTime] = React.useState(null);
	const { root, CLIENT_ROOT, extensionInstalled } = useSelector(state => state.api);
	const sign = useSelector(state => state.sign);

	const [key, setKey] = React.useState('a');
	const [Loaded, setLoaded] = React.useState(false);

  const location = useLocation();
  const url = (location.pathname+location.search).substr(1)
	const user = useSelector(state => state.user);

  const extra = React.useRef(null)

  const dispatch = useDispatch();

	//const url_string = props.match.params.url;

  let navigate = useNavigate();

  const isDesktop = useMediaQuery({
    query: '(min-width: 1224px)'
  })


  const openExtensionPage= () => {
    var url = 'https://chrome.google.com/webstore/detail/trends/malnhaodjdegcljjebmpopbjmckblndn';
    window.open(url,'_blank');
  }

  const rootA = process.env.NODE_ENV === 'development'? 'http://localhost:8080':'https://api.platfor.io'; 

  const handleSignOut = () => {
		fetch(rootA + `/auth/signout`, { credentials: 'include' })
			.then(res => {
				return res.json();
			})
  		.then(json => {
  				console.log('json is', json)
  				dispatch({type: 'SET_USER', path: '/auth/user', method: 'get'});
  			})
  		.catch(err => console.log(err))
  }

  const validURL = string => {
      const  url = 'https://' + string;
      console.log('url is', url);
      try { return Boolean(new URL(url)); }
      catch(e){ console.log('not valid'); return false; 
    }
  }

  useEffect(() => {
    const image1 = new Image();
    const image2 = new Image();
    image1.src = './sample1.png';
    image2.src = './sample2.png';
  }, []);

  /*
  useEffect(() => {
    const socket = io('http://localhost:8080/user', {
      autoConnect: false,
      reconnection: false,
      withCredentials: true,
      cors: {
        origin: "http://localhost:8080",
        methods: ["GET", "POST"]
      }
    });
  }, []);
  */

  const handleClose = () => { 
    dispatch({ type: 'CLOSE_SIGN_$' })
  };

  const openGCP = () => {
    var url = 'https://cloud.google.com/security';
    window.open(url,'_blank');
  }

  const openTwitter = () => {
    var url = 'https://reddit.com/r/hashely';
    window.open(url,'_blank');
  }

  
  const signIn = name => {
    const root = process.env.NODE_ENV === 'development'? 'http://localhost:8080':'https://api.socialbrowse.us'; 
    window.location.href = `${root}/auth/${name}?url=${encodeURIComponent(window.location.href)}`;
  };

  let h1 = '';
  let ps = ['Mark the part you use on what you needs', 'Access them on the same screen'];
  let signInWord = 'Alreaedy installed Plat? Please sign in'; 
  let how = 'Leave your comment on any webpage screen. Your action is notified to the o ther browser users following you or interested in the webpage keywords in real time. They can reply to your words viewing the same screen and suggest you the webpages you need in the same way.';
  let notice = '**Platfor records people\'s speeches on the public URL of a webpage. It doesn\'t work on Private webpage and cannot access any of your personal information'; 
 
  let ex1_h1 = 'Visit your storage screen anytime';
  let ex1_ps = [ 'Visit your bag webpage anytime', 'You can normally interact with what you collected']

  let ex2_h1 = 'Chat with friends on it'; 
  let ex2_ps = [ 'You can tag @others on your label', 'Your friends can react on the object in real time']

  let ex3_h1 = 'Always free and safe';
  let ex3_ps = [ 'Socialbrowse save your sticker on the public URL of a webpage like Facebook, Twitter', 'It only works on the public webpages and cannot access any of your private information']

  let kr_h1 = '#해쉬태그를 붙이세요';
  let kr_ps = ['화면 위 어떤 것에든 스티커를 붙이세요', '발견한 정보를 쉽게 수집하고 공유하세요'];
  let kr_signInWord = 'PLAT을 설치하셨나요? 로그인하세요'
  let kr_how = '모든 웹페이지 화면에 당신의 생각을 자유롭게 남기세요. 당신의 활동은 당신을 팔로우하거나 해당 웹페이지 키워드에 관심있는 브라우저 이용자들에게 실시간으로 전달됩니다. 그들은 같은 화면에서 당신의 말에 답변할 수 있고 같은 방식으로 당신이 필요한 웹페이지를 추천해줄 수 있습니다.'
  let kr_notice = 'Socialbrowse는 사람들의 대화를 웹페이지의 공개 URL에 저장합니다. 따라서 사적인 웹페이지에서는 작동하지 않으며 당신의 어떠한 개인정보에도 접근할 수 없습니다.';

  let kr_ex1_h1 = '언제든지 돌아가세요';
  let kr_ex1_ps = [ '스티커를 붙인 부분을 요약해 카드를 생성합니다', '스티커를 붙인 정보들을 한꺼번에 볼 수 있습니다. 언제든지 카드를 클릭해 스티커를 붙인 타겟으로 돌아갈 수 있습니다']
  let kr_ex2_h1 = '스티커를 다른 사람들과 공유하세요';
  let kr_ex2_ps = [ '스티커의 공유 범위를 설정할 수 있습니다', '친구들은 브라우저로 실시간 알림을 받습니다. 당신이 스티커를 붙인 부분에 반응을 남길 수 있습니다']
  let kr_ex3_h1 = '항상 무료이고 안전합니다';
  let kr_ex3_ps = [ 'Socialbrowse는 당신의 스티커를 Facebook, Twitter처럼 웹페이지의 공개 URL에 저장합니다', '누구에게나 공개된 웹페이지 화면에서만 작동하며 당신의 어떠한 개인정보에도 접근할 수 없습니다']

  if(location.pathname=='/kr'){h1 = kr_h1; ps = kr_ps;
    how = kr_how;
    notice = kr_notice;

    ex1_h1 = kr_ex1_h1;
    ex1_ps = kr_ex1_ps;

    ex2_h1 = kr_ex2_h1;
    ex2_ps = kr_ex2_ps;

    ex3_h1 = kr_ex3_h1;
    ex3_ps = kr_ex3_ps;
  }

  const { lang } = useParams();

  //<h1 className={classes.h1}>Stay connected with friends while surfing internet</h1>
	return (
      <>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: '10px'}}>
          <div style={{ maxWidth: '700px', marginBottom: '50px', marginTop: '100px' }}>
            {
              /*
                <Typography color="secondary">To Google Ads team, this newsfeed webpage is designed to work properly after installing Chrome extension and signing in. We signed you in with a demo user. Please click the people icon above and check the newsfeed where Google in-feed ads is inserted.</Typography> 
              */
            }
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <h1 className='h1'><span><img src={pinIcon} style={{width: '70px', marginRight: '10px'}}/>Collect anything from screen</span></h1>
              {
                /* 
                  <div style={{display: 'flex', flexDirectiyour 'row'}}>
                    <img src={scrapbook} style={{width: '150px', height: '120px', borderRadius: '20px'}}/>
                    <img src={newspaperClippings} style={{marginLeft: '10px', width: '120px', height: '120px', borderRadius: '20px'}}/>
                  </div>
                */
              }


            </div>
            {/* <h1 classNafe='h1'>{h1}</h1> */}
           
            <p style={{textAlign: 'left', fontSize: '15px', marginTop: '50px'}}>Choose<span style={{color: 'blue'}}> anything useful</span> on your screen</p>
            <p style={{textAlign: 'left'}}>Access them on <span style={{color: 'blue'}}>your one webpage</span> anytime</p>
            {/* <p style={{textAlign: 'left', fontSize: '15px', marginTop: '50px'}}>{ps[0]}</p>
            <p style={{textAlign: 'left'}}>{ps[1]}</p> */}
            <ExtensionButton/>
            
            {/* {
              extensionInstalled&&!user
              ?
                <Button style={{marginTop: '10px'}} variant='outlined' onClick={()=>navigate('/extension/signin')}>Sign in</Button>
              :
                null
            } */}
            {/* <Button style={{marginTop: '10px', backgroundColor: '#add8e6'}} variant='contained' onClick={()=>openExtensionPage()}>Download Hashely on Browser</Button> */}


            <div align='left'>
              { 
                /*
                  <a 
                    style={{ cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }}
                    onClick={openTwitter}
                  > 
                    <img src={TwitterIcon} style={{width: '20px', height: '20px'}} alt="Twitter logo" />
                  </a>
                */
              }
              <Button onClick={openTwitter}>Contact</Button>
              <Button onClick={()=>navigate('/privacy')}>Privacy</Button>
              {
                /*
                <a 
                  style={{ cursor: 'pointer'}}
                  onClick={openGCP}
                > 
                  <img src="/img/GCP.png" style={{width: '160px', height: '40px'}} alt="Google Cloud Platform logo" />
                </a>
                */
              }
            </div>
            <div align='left' style={{paddingTop: '17px'}}>
              <span style={{textAlign: 'left', fontSize: '15px'}}>Available with</span>
              <span style={{margin: '5px'}}>
                {
                  //<img style={{ width: '30px', height: '30px'}} src={FacebookIcon}/>
                  //<img style={{width: '30px', height: '30px'}} src={TwitterIcon}/>
                }
                <img className='image' style={{width: '30px', height: '30px'}} src={GoogleIcon}/>
              </span>
              <span style={{textAlign: 'left', fontSize: '15px'}}>accounts</span>
              {
                /*
                user?
                  null
                :
                  location.pathname == '/kr'?
                    <span style={{textAlign: 'left', fontSize: '15px'}}></span>
                  :
                    <span style={{textAlign: 'left', fontSize: '15px'}}>Available with</span>
                */
              }
              {
                /*
                  user?
                    null
                  :
                    <span>
                      <img style={{width: '30px', height: '30px'}} src={FacebookIcon}/>
                      <img style={{width: '30px', height: '30px'}} src={GoogleIcon}/>
                      <img style={{width: '30px', height: '30px'}} src={TwitterIcon}/>
                    </span>
                */
              }
            </div>
            {
              /*
              user?
                null
              :
              location.pathname == '/kr'?
                <div>
                  <p>이미 플랫을 설치하셨나요?</p>
                  <Button variant='contained' color='secondary' onClick={()=>dispatch({type: 'OPEN_SIGN_$'})}>로그인하세요</Button>
                </div>
              :
                <div>
                  <Button variant="contained" color='secondary' onClick={()=>dispatch({type: 'OPEN_SIGN_$'})}>Sign in</Button>
                </div>
              */
            }
            </div>
            <img className='image' style={{width: '80%'}} src={extra1}  alt="img of people talking on the shared screen"/>
            {
              /*
                <ReactCSSTransitionReplace
                  transitionName="cross-fade"
                  transitionEnterTimeout={2000}
                  transitionLeaveTimeout={2000}
                >
                  <div key={key}>
                   {
                    key==='a'
                     ?
                       <div>
                         <img src={sample1} style={{width: '80%', maxHeight: '400px', margin: 'auto'}}  alt="img of example website"/>
                       </div>
                      
                     :
                       <div>
                         <img src={sample2} style={{width: '80%', maxHeight: '400px', margin: 'auto'}} alt="communcation board for example website"/>
                       </div>
                   }
                  </div>
                </ReactCSSTransitionReplace>

        <Dialog
          open={isHowOpen}
          onClose={()=>setIsHowOpen(false)}
        >
          <p>
            {how}
          </p>
          <p style={{color: 'blue'}}>
            {notice}
          </p>
        </Dialog>
              */
        }
        {/*
          <div style={{position: 'absolute', bottom: 50}}>
            <Button onClick={()=>extra.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}>How it works</Button>
          </div>
          */
        }
        </div>

        <div ref={extra} className='intro'>
          <div style={{ maxWidth: '550px',  marginBottom: '50px' }}>
            <h1 className='h1'>{ex1_h1}</h1>
            <p style={{textAlign: 'left', fontSize: '15px', marginTop: '50px'}}>{ex1_ps[0]}</p>
            <p style={{textAlign: 'left'}}>{ex1_ps[1]}</p>
          </div>
          <img className='image' src={extra2}  alt="img of product sample"/>
        </div>

        {/* <div className='intro'>
            <div style={{ maxWidth: '550px', marginBottom: '50px' }}>
              <h1 className='h1'>{ex2_h1}</h1>
              <p style={{textAlign: 'left', fontSize: '15px', marginTop: '50px'}}>{ex2_ps[0]}</p>
              <p style={{textAlign: 'left'}}>{ex2_ps[1]}</p>
            </div>
            <img className='image' src={intro} alt="img of how this product works"/>
           
        </div>
        */}

        <div className='intro'>
          <div style={{ maxWidth: '550px', marginBottom: '50px' }}>
            <h1 className='h1'>{ex3_h1}</h1>
            <p style={{textAlign: 'left', marginTop: '50px'}}>{ex3_ps[0]}</p>
            <p style={{textAlign: 'left'}}>{ex3_ps[1]}</p>
          </div>
          <img className='image' src={extra3} alt="img of explaining security details"/>
        </div>
      </>
    );
}

export default Home;
