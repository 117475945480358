import React, { useEffect } from 'react';
import clsx from 'clsx';
import { red, grey } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";


import { Paper, Chip, IconButton } from '@mui/material';

import FavoriteIcon from '@mui/icons-material/Favorite';
import { useSelector, useDispatch } from 'react-redux';

const CHIP_MAX_WIDTH =  100;
const CHIP_ICON_WIDTH = 30;

const EllipsisText = props => {
  const { children } = props

  return (
    <div style={{
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: 100,
		}}>
      {children}
    </div>
  )
}

const Chips = props => {
	const { person, isOwner } = props; 
	const { hashtags } = person;
	//const [ entityStrings, setEntityStrings ] = React.useState(a);

  const user = useSelector(state => state.user);
  const { root } = useSelector(state => state.api);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleClick = hashtag => {
		props.setIsBookmarkLayout(true);
		navigate(`/${person.username}/${hashtag.name}`)
		//dispatch({type:'ACTIVITY_BY_TAG', method: 'get', path: `/api/activity/init?personName=${person.username}&hashtagName=${encodeURIComponent(hashtag.name)}`, hashtag});
	}

	const handleHeartClick = hashtag => {
		dispatch({type:'ACTIVITY_BY_TAG', method: 'get', path: `/api/activity/init?personName=${person.username}&hashtagName=like`, hashtag});
	}

	const handleDelete = (hashtag, e) => {
		console.log('handleDelete')
		e.stopPropagation();
		if(user.subscriptionStatus == 'FREE'){
			const okay = window.confirm(
				'You are on free plan. Your action will remove the canvas entirely. Are you sure to remove this canvas?'
			)
			if(okay){
				fetch(`${root}/api/canvas?canvasName=${hashtag.name}`, 
	        { 
	          method: 'delete', 
	          credentials: 'include' 
	        }
	      )
	        .then(res => res.json())
	        .then(json => {
	          console.log('json is', json)
	        })

				dispatch({type:'DELETE_USER_TAG_$', method: 'delete', hashtag});
				return;
			} else {
			}
		} else {
			dispatch({type:'DELETE_USER_TAG', method: 'delete', path: `/api/user/hashtag/?hashtagName=${encodeURIComponent(hashtag.name)}`, hashtag});
		}
	}

	return (
    <div style={{marginTop: 'auto'}}>
    	Your bags :   
	    <Chip
			color='primary'
	      	style={{backgroundColor: 'grey', marginLeft: '5px'}}
			label={<EllipsisText>{'Default'}</EllipsisText>}
			onClick={() => navigate(`/${person.username}/no_hashtag`)}
			clickable={true}
		/>
      {
      	/*
        <IconButton onClick={handleHeartClick} size="large">
          <FavoriteIcon style={{color: 'red'}} />
        </IconButton>
        */
    	}
			{
				hashtags&&hashtags.map(tag => {
					return (
						<Chip
							color='primary'
							style={{backgroundColor: tag.color, marginLeft: '5px'}}
							label={<EllipsisText>{'#' + tag.name}</EllipsisText>}
							onClick={() => handleClick(tag)}
							onDelete={isOwner? e => handleDelete(tag, e) : null}
							clickable={true}
						/>
					);
			})}
		</div>
    );
}

export default Chips;
